/**
 * This file was automatically generated by Strapi.
 * Any modifications made will be discarded.
 */
import strapiCloud from "/admin/node_modules/.strapi/vite/deps/@strapi_plugin-cloud_strapi-admin.js?v=fe2be019";
import graphql from "/admin/node_modules/.strapi/vite/deps/@strapi_plugin-graphql_strapi-admin.js?v=21669ca0";
import usersPermissions from "/admin/node_modules/.strapi/vite/deps/@strapi_plugin-users-permissions_strapi-admin.js?v=46e8953e";
import { renderAdmin } from "/admin/node_modules/.strapi/vite/deps/@strapi_strapi_admin.js?v=5e12c58b";

renderAdmin(document.getElementById("strapi"), {
  plugins: {
    "strapi-cloud": strapiCloud,
    graphql: graphql,
    "users-permissions": usersPermissions,
  },
});
